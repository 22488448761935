.CompanyCard {
    text-decoration: none;
    margin-bottom: 1rem;
    color: inherit;
    border-radius: 5px;
    border: none;
    padding: 1rem .5rem .5rem;
    transition: top ease 0.5sec;  
  }

  .CompanyCard img {
    width: 50px;
  }
  
  .CompanyCard:hover { 
    color: black;
    box-shadow:
       inset 0 -1em 1em rgba(0,0,0,0.1),
             0 0 0 1px rgb(255,255,255), 
             0.3em 0.3em 1em rgba(0,0,0,0.3);
    top: -1px;
  }

  .CompanyCard .card-title {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }

.JobCard {
    display: flex;
    max-height: 250px;
    margin: 1rem 2rem;
    padding: 1rem 1rem 0.5rem;
    color: inherit;
    border-radius: 5px;
    border: none;
    transition: top ease 0.5sec;
  }
  
.JobCard:hover { 
    color: black;
    box-shadow:
       inset 0 -1em 1em rgba(0,0,0,0.1),
             0 0  0 1px rgb(255,255,255),
             0.3em 0.3em 1em rgba(0,0,0,0.3);
    top: -1px;
}

.JobCard .card-title h5 {
    margin-top: .5rem;
    font-weight: 700;
    padding-bottom: .5rem;
    color: darkblue;
}

.JobCard .card-body p {
    margin-bottom: 0.25rem;
}

.apply-btn {
    margin-top: 1rem;
    margin-bottom: 0;
}

.applied {
    color: green;
    margin-top: 1rem;
}
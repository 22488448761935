.SignupForm {
    padding: 1rem 2rem;
}

.form-control {
    margin-bottom: 1rem;;
}

h1 {
    margin-bottom: 1.5rem;
}


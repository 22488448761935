@import '~bootstrap/dist/css/bootstrap.css';
html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0 0 30px 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


h1, h2, h3, h4, .lead {
  color: rgb(46, 46, 46);
}

h4 + p,
h2 + p {
  color: rgb(58, 58, 58);
}

p {
  font-size: 1.125rem;
}

.btn-primary,
.btn-primary:focus {
  background-color: rgb(142, 142, 142);
  border: none;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.523);
  margin-bottom: 1rem;
}

.btn-primary:hover {
  background-color: rgb(108, 108, 108);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input:focus,
input:active,
button:focus,
button:active {
  box-shadow: none !important;
}

label {
  font-weight: bold;
}
